/* eslint-env browser */

import {PushClient} from './push-client.js';
import {uint8ArrayToBase64} from './encryption/helpers.js';

class AppController {
	constructor() {
		this._stateChangeListener = this._stateChangeListener.bind(this);
		this._subscriptionUpdate = this._subscriptionUpdate.bind(this);

		this._pushClient = new PushClient(
			this._stateChangeListener,
			this._subscriptionUpdate,
			webpush_public_key,
		);

		this._toggleSwitch = getElement('#enable-push-switch');
		this._toggleSwitch.addEventListener('click', () => this.togglePush());
	}

	togglePush() {
		if (this._toggleSwitch.checked) {
			this._pushClient.subscribeDevice();
		} else {
			this._pushClient.unsubscribeDevice();
		}
	}

	registerServiceWorker() {
		// Check that service workers are supported
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.register('/service-worker.js')
				.catch((err) => {
					console.error(err);
					this.showErrorMessage(
						'Unable to Register SW',
						'Sorry this requires a service worker to work and it ' +
                'failed to install - sorry :(',
					);
				});
		} else {
			this.showErrorMessage(
				'Service Worker Not Supported',
				'Sorry this requires service worker support in your browser. ' +
          'Please try this in Chrome or Firefox Nightly.',
			);
		}
	}

	_stateChangeListener(state, data) {
		if (typeof state.interactive !== 'undefined') {
			if (state.interactive) {
				this._toggleSwitch.disabled = false;
			} else {
				this._toggleSwitch.disabled = true;
			}
		}

		if (typeof state.pushEnabled !== 'undefined') {
			if (state.pushEnabled) {
				this._toggleSwitch.checked = true;
			} else {
				this._toggleSwitch.checked = false;
			}
		}

		switch (state.id) {
		case 'UNSUPPORTED':
			this.showErrorMessage(
				'Push Not Supported',
				data,
			);
			break;
		case 'ERROR':
			this.showErrorMessage(
				'Ooops a Problem Occurred',
				data,
			);
			break;
		default:
			break;
		}
	}

	_getNotificationDevices(platform) {
		return new Promise((resolve, reject) => {
			const path = `/api/device/${platform}/`;
			fetch(path, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}).then((response) => {
				if (response.ok) {
						response.json().then((data) => {
						resolve(data);
					}).catch((error) => {
						console.error(error);
						reject(error);
					});
				}
			}).catch((error) => {
				console.error(error);
				reject(error);
			});
		});
	}

	_addNotificationDevice(platform, device) {
		const path = `/api/device/${platform}/`;
		fetch(path, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-CSRFToken": csrftoken,
			},
			mode: 'same-origin',
			body: JSON.stringify(device),
		}).then((response) => {
			if (response.ok) {
				response.json().then((data) => {
					console.log(data);
				}).catch((error) => {
					console.error(error);
				});
			}
		}).catch((error) => {
			console.error(error);
		});
	}

	_deleteNotificationDevice(platform, device) {
		const path = `/api/device/${platform}/${encodeURIComponent(device.registration_id)}/`;
		fetch(path, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				"X-CSRFToken": csrftoken,
			},
			mode: 'same-origin',
		}).then((response) => {
			if (response.ok) {
				response.json().then((data) => {
					console.log(data);
				}).catch((error) => {
					console.error(error);
				});
			}
		}).catch((error) => {
			console.error(error);
		});
	}

	_subscriptionUpdate(subscription, previousSubscription) {
		this._currentSubscription = subscription;
		if (!subscription) {
			// Remove any subscription from your servers if you have
			// set it up.
			if (previousSubscription && previousSubscription.endpoint) {
				this._deleteNotificationDevice('webpush', {
					registration_id: previousSubscription.endpoint,
				});
			}
			return;
		}

		const sub = subscription.toJSON();
		this._addNotificationDevice('webpush', {
			name: navigator.userAgent,
			browser: navigator.userAgent.match(/(firefox|chrome|safari)/ig)[0].toUpperCase(),
			application_id: 'web_app',
			registration_id: sub.endpoint,
			auth: sub.keys.auth,
			p256dh: sub.keys.p256dh,
		});
	}

	toBase64(arrayBuffer, start, end) {
		start = start || 0;
		end = end || arrayBuffer.byteLength;

		const partialBuffer = new Uint8Array(arrayBuffer.slice(start, end));
		return window.btoa(String.fromCharCode.apply(null, partialBuffer));
	}

	toHex(arrayBuffer) {
		return [...new Uint8Array(arrayBuffer)]
			.map((x) => x.toString(16).padStart(2, '0'))
			.join(' ');
	}

	showErrorMessage(title, message) {
		alert(`${title}: ${message}`);
	}
}

// This is a helper method so we get an error and log in case we delete or
// rename an element we expect to be in the DOM.
function getElement(selector) {
	const e = document.querySelector(selector);
	if (!e) {
		console.error(`Failed to find element: '${selector}'`);
		throw new Error(`Failed to find element: '${selector}'`);
	}
	return e;
}

if (window && window.ReactNativeWebView === undefined && window.location.pathname.startsWith('/users/')) {
	window.onload = function() {
		if (!navigator.serviceWorker) {
			console.warn('Service workers are not supported in this browser.');
			return;
		}

		if (!('PushManager' in window)) {
			console.warn('Push is not supported in this browser.');
			return;
		}

		console.debug('Setting up demo.');
		const appController = new AppController();
		appController.registerServiceWorker();
	};
}
